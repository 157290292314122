import {Card, Container, Stack, Typography} from '@mui/material';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import Row from 'src/components/ui/Row';
import {deleteQuantityUpdate, getQuantityUpdate} from 'src/utils/AdminApi';
import {canAccess} from 'src/utils/permissions';

export default function QuantityUpdatePage() {
	const {id} = useParams();
	const nav = useNavigate();
	const {data} = useQuery(
		['product-quantity-updates', id],
		() => {
			return getQuantityUpdate(id);
		},
		{
			refetchOnWindowFocus: false,
			enabled: id != null,
		},
	);

	const handleDelete = () => {
		deleteQuantityUpdate(id).then(() => {
			toast.success('تم الحذف بنجاح');
			nav(-1);
		});
	};

	if (!data) return;

	return (
		<Container maxWidth="md">
			<Card sx={{p: 4}}>
				<Row label="تفاصيل" value={data.description} />
				<Row label="المنتج" value={data.product.name} />
				<Row
					label="التغير في الكمية"
					value={data.quantity >= 0 ? '+' + data.quantity : data.quantity}
				/>
				<Row label="بواسطة" value={data.creator?.name ?? '-'} />
			</Card>
			{canAccess() && (
				<Stack alignItems="center" justifyContent="center" direction="row" my={2}>
					<DeleteAlertDialog onDelete={handleDelete} />
					<Typography>حذف</Typography>
				</Stack>
			)}
		</Container>
	);
}
