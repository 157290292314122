// import {Icon} from '@iconify/react';

import {
	createOrderPermission,
	findOrderPermission,
	findProductPermission,
} from 'src/permissionsList';
import {isMobile} from 'src/utils/deviceType';
import {canAccess} from 'src/utils/permissions';
import {accountIs, accountOneOf} from 'src/utils/siteUtils';
import {getStartAndEndOfToday, getTodayDateString} from 'src/utils/date';
import {isFeatureAllowed} from 'src/utils/featureUtils';
import {invoices_feature_flag} from 'src/feature_flag_list';
import {DateTime} from 'luxon';
import getRamadanTimes from 'src/utils/getRamadanTimes';
import {BETA_FEATURES} from 'src/types/enums/BETA_FEATURES';

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const imageSize = 20;
const smallIconSize = 18;
const getImageIcon = (path, size = imageSize) => <img src={path} width={size} height={size} />;

const sidebarConfig = (admin) => {
	const routes = [
		{
			title: 'الرئيسية',
			path: '/',
			icon: getImageIcon('/static/icons/sidebar/home.png'),
		},
	];

	if (canAccess(findOrderPermission)) {
		if (process.env.NODE_ENV === 'development') {
			const childrens = [
				{
					title: 'طلبات المتجر',
					path: '/orders/shop',
					icon: getImageIcon('/static/icons/sidebar/box.png', smallIconSize),
				},
				{
					title: 'طلبات التوصيل',
					path: '/orders/delivery',
					icon: getImageIcon('/static/icons/sidebar/box.png', smallIconSize),
				}
			]

			routes.push({
				title: 'الطلبات',
				// path: '/orders',
				path: '/orders',
				icon: getImageIcon('/static/icons/sidebar/box.png'),
				children : childrens
			});
		
		}else {

			routes.push({
				title: 'الطلبات',
				// path: '/orders',
				path: '/orders',
				icon: getImageIcon('/static/icons/sidebar/box.png'),
			});
		}
	}

	if (canAccess(createOrderPermission)) {
		if (isMobile()) {
			routes.push({
				title: 'اضافة طلب',
				path: '/orders/create',
				icon: getImageIcon('/static/icons/sidebar/pos.png'),
			});
		} else {
			routes.push({
				title: 'كاشير',
				path: '/orders/pos',
				icon: getImageIcon('/static/icons/sidebar/pos.png'),
			});
		}
	}

	if (canAccess(findProductPermission)) {
		const childrens = [
			{
				title: 'غير متوفرة',
				path: '/products-out-of-stock',
				icon: getImageIcon('/static/icons/sidebar/out-of-stock.png', smallIconSize),
			},
			{
				title: 'غير معروضة',
				path: '/draft-products',
				icon: getImageIcon('/static/icons/sidebar/draft.png'),
			},
		];
		// show category tab only for store owner
		if (canAccess())
			childrens.push({
				title: 'التصنيفات',
				path: '/categories',
				icon: getImageIcon('/static/icons/sidebar/menu.png', smallIconSize),
			});
		routes.push({
			title: 'المنتجات',
			path: '/products',
			icon: getImageIcon('/static/icons/sidebar/inventory.png'),
			children: childrens,
		});
	}

	if (isFeatureAllowed(BETA_FEATURES.Reports))
		routes.push({
			title: 'التقارير',
			path: '/reports',
			icon: getImageIcon('/static/icons/sidebar/statistics.png'),
		});

	if (admin.isOwner === true) {
		// if (accountIs('demo')) {
		// 	routes.push();
		// }

		routes.push({
			title: 'المشتريات',
			path: '/purchases',
			icon: getImageIcon('/static/icons/sidebar/shopping-bag.png'),
		});

		if (isFeatureAllowed(invoices_feature_flag)) {
			const {start, end} = getStartAndEndOfToday();

			routes.push({
				title: 'الفواتير',
				path: `/invoices?paid_at_gte=${start}&paid_at_lte=${end}`,
				icon: getImageIcon('/static/icons/sidebar/invoices.png'),
			});
		}

		routes.push({
			title: 'المتجر',
			path: '/site',
			icon: getImageIcon('/static/icons/shop.svg'),
			children: [
				{
					title: 'الشحن',
					path: '/delivery-methods',
					// isNew: true,
					icon: getImageIcon('/static/icons/sidebar/delivery.png', smallIconSize),
				},
				{
					title: 'طرق الدفع',
					path: '/payment-methods',
					icon: getImageIcon('/static/icons/sidebar/payment.png', smallIconSize),
				},
				{
					title: 'تطبيقات',
					path: '/apps',
					icon: getImageIcon('/static/icons/sidebar/integration.png', smallIconSize),
				},
				{
					title: 'الادارة',
					path: '/admins',
					icon: getImageIcon('/static/icons/people.svg', smallIconSize),
				},
			],
		});

		if (accountOneOf('feza')) {
			routes.push({
				title: 'مالية',
				path: '/finance',
				isNew: true,
				icon: getImageIcon('/static/icons/sidebar/shopping-bag.png'),
			});
		}
		// {
		// 	title: 'المدفوعات',
		// 	path: '/billing',
		// 	icon: getImageIcon('/static/icons/sidebar/tag.png'),
		// },
	}

	routes.push(
		{
			title: 'حسابي',
			path: '/me',
			icon: getImageIcon('/static/icons/account.svg'),
		},
		{
			title: 'إقتراحات',
			path: '/suggestions',
			icon: getImageIcon('/static/icons/sidebar/suggestion.png'),
		},
	);
	return routes;
};
export default sidebarConfig;
