import {Button, Card, Container, Fab, Stack, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Counter from 'src/components/inputs/Counter';
import Picker from 'src/components/inputs/Picker';
import {LoadingView} from 'src/routes';
import {
	addOrderItem,
	deleteOrderItems,
	getOrder,
	getProducts,
	request,
	updateOrderItem,
} from 'src/utils/AdminApi';
import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import filterDeleted from 'src/utils/filterDeleted';
import {handleRequestError, isNullOrEmpty, updateArray} from 'src/utils/helperMethods';
import {getSiteCurrency} from 'src/utils/selectors';
import {getImageSrc} from 'src/utils/productUtils';
import useMultipleChanges from 'src/hooks/useMultipleChanges';
import MuiLink from 'src/components/ui/StyledLink';
import CreatableSelect from 'src/components/inputs/CreatableSelect';
import {useDebounce} from 'src/hooks/useDebounce';
import ResponsiveDialog from 'src/components/modals/ResponsiveDiaog';
import useVisible from 'src/hooks/useVisible';
import ProductsGridSelection from '../products/common/ProductsGridSelection/ProductsGridSelection';
import useOrderItemsSelection from './NewAddOrderPage/useOrderItemsSelection';
import CartItem from './CartItem.item';
import {buildOrderItem} from 'src/utils/orderItemUtils';
import {strapiCreate} from 'src/utils/strapiApi';
import ItemBranchName from 'src/components/ItemBranchName';
export default function UpdateOrderItemsPage() {
	let {id} = useParams();
	const [orderItems, setItems] = useState([]);
	const {
		data: order,
		refetch,
		isLoading,
	} = useQuery(['order', id], () => getOrder(id), {
		enabled: !!id,
		refetchOnWindowFocus: false,
		// we need local state to update items after changes are saved
		// we need to filter soft deleted items
		onSuccess: (data) => setItems(filterDeleted(data.items)),
	});
	//loading state
	const [isSaving, setIsSaving] = useState(false);

	const {changes, clearItemChanges, handleValueChange} = useMultipleChanges();
	const onSaveItemChangesClicked = async (item, changes) => {
		setIsSaving(true);
		try {
			const newQuantity = changes[item.id].quantity;
			const updates = {quantity: newQuantity};
			if (newQuantity <= 0 || isNaN(newQuantity)) {
				toast.error('كمية غير صحيحة');
				return;
			}
			const res = await updateOrderItem(item.id, updates);
			// to hide save button for this item
			clearItemChanges(item.id);
			// update item state so that our compare changes function work fine if user want to change the item again
			setItems(updateArray(orderItems, res, 'id'));
		} catch (error) {
			toast.error(handleRequestError(error));
		} finally {
			setIsSaving(false);
		}
	};

	const deleteItem = async (item) => {
		try {
			await deleteOrderItems(item.id, 'no reason');
			setItems(orderItems.filter((i) => i.id != item.id));
		} catch (err) {
			//
		}
	};

	if (isLoading) return <LoadingView />;

	return (
		<Container maxWidth="md">
			<Card sx={{p: 2}}>
				<Stack spacing={2}>
					<Typography variant="h3">تعديل الطلب</Typography>

					<Stack spacing={1}>
						{orderItems.map((item) => {
							const itemChanges = changes[item.id];
							const itemChanged = !!itemChanges;
							const newQuantity = itemChanges?.quantity;
							return (
								<Card key={item.id} sx={{p: 1}}>
									<Stack
										alignItems={{xs: 'default', sm: 'center'}}
										direction={{xs: 'column', sm: 'row'}}
										justifyContent="space-between"
									>
										{/* metadata */}
										<Stack spacing={1}>
											<Stack spacing={2} direction="row">
												<img
													loading="lazy"
													alt={item.product.name}
													width={50}
													height={50}
													src={getImageSrc(item.product)}
												/>
												<Stack>
													<MuiLink to={`/products/${item.product.id}`}>
														<Typography>
															{item.product.name} {item.variation?.name && `- ${item.variation?.name}`}
														</Typography>
													</MuiLink>

													<Stack spacing={3} direction="row" justifyContent="space-between">
														<Typography>
															{calcPriceAfterDiscount(item.price, item.discount)} {getSiteCurrency()}
														</Typography>
														<ItemBranchName item={item} />
													</Stack>
												</Stack>
											</Stack>
										</Stack>
										{/* actions */}
										<Stack justifyContent="space-between" alignItems="center" spacing={1} direction="row">
											<Counter
												defaultValue={item.quantity}
												onChange={(newValue) => handleValueChange(newValue, item, 'quantity')}
												min={1}
											/>
											{/* <Button onClick={() => deleteItem(item)}>delete</Button> */}

											<Fab
												sx={{opacity: itemChanged ? 1 : 0.5}}
												disabled={!itemChanged || isSaving}
												onClick={() => onSaveItemChangesClicked(item, changes)}
												// sx={{position: 'fixed', right: 40, bottom: 80}}
												variant="extended"
												color="primary"
												size="small"
											>
												حفظ
											</Fab>
										</Stack>
										{/* <Typography>{item.variation ? item.variation.quantity : item.product.quantity}</Typography> */}
									</Stack>
								</Card>
							);
						})}
					</Stack>
					<AddOrderItemForm
						refetchOrder={refetch}
						orderId={order.id}
						alreadySelectedItems={orderItems.map((i) => ({
							productId: i.product.id,
							variationId: i.variation?.id,
						}))}
					/>
				</Stack>
			</Card>
		</Container>
	);
}

export function AddOrderItemForm({orderId, refetchOrder, alreadySelectedItems = []}) {
	const {isVisible, show, hide, toggle, setIsVisible} = useVisible();

	const [selectedItem, setSelectedItem] = useState(null);

	const {
		handleAddItem,
		handleItemQuantityChange,
		handleRemoveItem,
		addedProducts,
		checkAddedItemExists,
		updateItem,
		resetSelections,
		handleItemChange,
	} = useOrderItemsSelection();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const onSubmit = async (e) => {
		e.preventDefault();
		if (isSubmitting) return;
		if (isNullOrEmpty(addedProducts)) return toast.error('يرجي اختيار المنتج');
		setIsSubmitting(true);

		try {
			await request('/order-items/multiple', 'POST', {
				items: addedProducts.map((i) => {
					return {
						order: orderId,
						...buildOrderItem(i),
					};
				}),
			});

			resetSelections();
			// await Promise.allSettled(
			// 	addedProducts.map(async (product) => {
			// 		try {
			// 			await addOrderItem({
			// 				order: orderId,
			// 				...buildOrderItem(product),
			// 			});
			// 			handleRemoveItem(product.id);
			// 		} catch (err) {
			// 			updateItem(product.id, {error: true});
			// 		}
			// 	}),
			// );
			refetchOrder();
		} catch (error) {
			toast.error(handleRequestError(error));
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<Stack mt={2} spacing={1} direction="row" justifyContent="space-between">
				<Typography variant="h6">اضافة منتجات اخرى</Typography>
				<Button onClick={show}>اختر</Button>
			</Stack>
			<ResponsiveDialog
				maxWidth="md"
				open={isVisible}
				setOpen={setIsVisible}
				actions={
					<Button sx={{py: 1.1}} fullWidth onClick={() => hide()} variant="contained">
						تأكيد
					</Button>
				}
			>
				<ProductsGridSelection
					columns={{xl: 3}}
					checkAddedItemExists={checkAddedItemExists}
					handleAddItem={handleAddItem}
					handleRemoveItem={handleRemoveItem}
				/>
			</ResponsiveDialog>

			<Stack my={1} spacing={1}>
				<Stack mt={2} spacing={2}>
					{addedProducts.map((item) => {
						return (
							<Stack key={item.id}>
								<CartItem
									handleItemQuantityChange={handleItemQuantityChange}
									handleRemoveItem={handleRemoveItem}
									item={item}
									handleItemChange={handleItemChange}
								/>
								{item.error && (
									<Typography variant="body2" color="error">
										يوجد مشكلة في اضافة المنتج
									</Typography>
								)}
							</Stack>
						);
					})}
				</Stack>
			</Stack>

			{!isNullOrEmpty(addedProducts) && (
				<Button
					fullWidth
					sx={{mt: 5, py: 1.25}}
					disabled={isSubmitting}
					type="submit"
					color="secondary"
					variant="contained"
				>
					اضافة
				</Button>
			)}
		</form>
	);
}
