import {Box, Card, Container, Divider, Stack, TextField, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';

import {toast} from 'react-toastify';
import PhoneInput from 'src/components/inputs/PhoneInput';
import SubmitButton from 'src/components/buttons/SubmitButton';
import Picker from 'src/components/inputs/Picker';
import Row from 'src/components/ui/Row';
import FormikWrapper from 'src/components/wrappers/FormikWrapper';
import useFind from 'src/hooks/useFind';

import AccessControlWrapper from 'src/components/AccessControlWrapper';
import Page from 'src/components/Page';
import {
	addDiscountPermission,
	createOrderPermission,
	findOneOrderPermission,
} from 'src/permissionsList';
import {createOrder, getCities, request} from 'src/utils/AdminApi';
import {getCartItemsTotalPrice, getCartItemsTotalQuantity} from 'src/utils/cartItemUtils';
import {getCity} from 'src/utils/cityUtils';
import {getPriceString, handleRequestError, isNullOrEmpty} from 'src/utils/helperMethods';
import {
	buildCartItemFromOrderItem,
	buildOrderItem,
	isCartItemValid,
} from 'src/utils/orderItemUtils';
import {orderStatusOptions} from 'src/utils/orderUtils';
import {canAccess} from 'src/utils/permissions';
import {getSiteCurrency} from 'src/utils/selectors';
import * as yup from 'yup';
import CartItemSelector from '../CartItemSelector';
import useOrderItemsSelection from '../NewAddOrderPage/useOrderItemsSelection';
import {isFeatureAllowed} from 'src/utils/featureUtils';
import {invoices_feature_flag, order_selected_date_feature_flag} from 'src/feature_flag_list';
import {getNumber} from '../../../utils/numberUtils';
import {accountIs} from 'src/utils/siteUtils';
import CheckboxWrapper from 'src/components/inputs/CheckboxWrapper';
import {accountIsAssal, accountIsDemo} from 'src/utils/customSite';
import useSite from 'src/hooks/useSite';
import {useQuery} from 'react-query';
import {CustomAutoComplete} from 'vetrina-ui';
import InfoTip from 'src/components/ui/InfoTip';
import BasicRadioGroup from 'src/components/wrappers/BasicRadioGroup';
const schema = yup.object().shape({
	name: yup.string(),
	// .required('يرجي ادخال اسم الزبون'),
	email: yup.string().email('يرجي ادخال بريد الكتروني صحيح'),
	phone: yup
		.string('')
		// .required('يرجي ادخال رقم الهاتف')
		.min(9, 'رقم الهاتف يجب ان يتكون من ٩ ارقام'),
	address: yup.string(), //.required('يرجي ادخال العنوان'),
	city: yup.string().when('delivery_method', (delivery_method, field) => {
		if (delivery_method === 'delivery') {
			return field.required('يرجي اختيار المدينة');
		} else {
			// no need for validation
			return field;
		}
	}),
	district: yup.string().when('city', (city, field) => {
		const cityHasDistricts = city === 'طرابلس' || city === 'بنغازي';
		if (cityHasDistricts) {
			// district is required when city has districts
			return field.required('يرجي اختيار المنطقة');
		} else {
			// no need for validation
			return field;
		}
	}),
	discount: yup.number().typeError('يرجي ادخال رقم صحيح').min(0, 'قيمة الخصم يجب ان تكون موجبة'),
});

export default function CreateOrder() {
	const navigate = useNavigate();
	const {data: countries = []} = useQuery('countries', () => request('/countries'));
	const [cities] = useFind(getCities);
	// these variables for inputs coming from dublicate button in order details
	const location = useLocation();
	// we will get order from duplicate button , or product from barcode page
	const {order} = location.state ?? {};
	const tenant = useSite();
	const {
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		resetSelections,
		handleItemChange,
	} = useOrderItemsSelection(order?.items?.map(buildCartItemFromOrderItem) ?? []);

	const handleSubmit = async (values) => {
		if (addedProducts.length === 0) {
			return toast.error('يجب اختيار منتج واحد علي الاقل');
		}

		if (!isCartItemValid(addedProducts)) return toast.error('يرجى تعبئة جميع الحقول');
		const {
			down_payment,
			city,
			district,
			delivery_method,
			status,
			name,
			email,
			phone,
			address,
			delivery_price,
			note,
			discount,
			selected_date,
			// custom fields
			phone2 = '',
			country,
			is_cashier = false,
			payment_received = false,
		} = values;
		// const fullAddress = isNullOrEmpty(city) ? address : `${city} ${address}`;
		const isPickup = delivery_method === 'pickup';
		try {
			const res = await createOrder({
				address: !isPickup ? address : '',
				email,
				phone,
				name,
				payment: {
					gateway: values.gateway,
				},
				cart: addedProducts.map(buildOrderItem),
				delivery_price,
				note,
				status,
				down_payment: getNumber(down_payment),
				delivery_info: {
					type: delivery_method || 'pickup',
					city: !isPickup ? city : '',
					district: !isPickup ? district : '',
					country: country ?? 'LY',
				},
				custom_fields: {
					phone2,
				},
				is_cashier, //is_cashier,
				payment_received,
				discount,
				selected_date,
			});
			toast.success('تم انشاء الطلب بنجاح');
			if (canAccess(findOneOrderPermission)) navigate('/orders/' + res.uuid);
			else {
				navigate(0);
			}
		} catch (error) {
			handleRequestError(error, (status, responseBody) => {
				const errorBody = responseBody?.data;
				if (status === 406) {
					const errorId = errorBody?.id;
					if (errorId === 'CART_EMPTY') {
						return toast.error('يجب اختيار منتجات لإدخال الطلب');
					}
					return toast.error(responseBody.message);
				}

				if (responseBody && responseBody.message?.toLowerCase()?.includes('phone number')) {
					toast.error('يوجد مشكلة في انشاء رابط دفع باستخدام الرقم الذي تم ادخاله');
				}
			});
		}
	};

	return (
		<Page title="انشاء طلب">
			<AccessControlWrapper controllerAction={createOrderPermission}>
				<Container maxWidth="md">
					<FormikWrapper
						values={{
							name: order?.customer?.name ?? '',
							email: order?.customer?.email ?? '',
							phone: order?.customer?.phone ?? '',
							address: order?.address ?? '',
							note: order?.note ?? '',
							selected_date: order?.selected_date ?? '',
							discount: order?.discount,
							// delivery_price: order?.delivery_price ?? '',
							country: 'LY',
							delivery_method: order?.delivery_info?.type ?? 'pickup',
							city: order?.delivery_info?.city ?? '',
							district: order?.delivery_info?.district ?? '',
							payment_received: false,
							// this is beta feature, we will enable it for some users
							is_cashier: tenant.config?.features?.always_cashier ?? false,
							gateway: 'cash',
							// extra custom fields
							phone2: '',
						}}
						schema={schema}
						handleSubmit={handleSubmit}
					>
						{({values, setFieldValue, isSubmitting, getInputProps}) => {
							const selectedCity = getCity(cities, values.city);
							const cityHasDistricts = !isNullOrEmpty(selectedCity?.districts);
							return (
								<Stack spacing={2}>
									<Typography variant="h3">اضافة طلب</Typography>
									<Card sx={{p: 2}}>
										<CartItemSelector
											title="اختر المنتجات"
											handleItemQuantityChange={handleItemQuantityChange}
											checkAddedItemExists={checkAddedItemExists}
											handleAddItem={handleAddItem}
											handleRemoveItem={handleRemoveItem}
											handleItemChange={handleItemChange}
											addedProducts={addedProducts}
											resetSelections={resetSelections}
											defaultVisibility={addedProducts.length === 0}
											showBranch
										/>
										{addedProducts.length > 0 && (
											<Box bgcolor={'#F2F2F2'} mt={1}>
												<Row label="عدد القطع" value={getCartItemsTotalQuantity(addedProducts)} />
												<Divider />
												<Row
													label="مجموع المشتريات"
													value={getPriceString(getCartItemsTotalPrice(addedProducts), getSiteCurrency())}
												/>
											</Box>
										)}
									</Card>

									{addedProducts.length > 0 && (
										<>
											<Card sx={{p: 2}}>
												<Stack spacing={2}>
													<Typography variant="h6">معلومات الزبون</Typography>

													<TextField fullWidth type="text" label="اسم الزبون" {...getInputProps('name')} />
													<PhoneInput
														label="رقم الهاتف"
														placeholder="رقم الهاتف"
														{...getInputProps('phone')}
														fullWidth
														type="tel"
													/>
													<PhoneInput
														label="رقم هاتف - احتياطي"
														placeholder="رقم هاتف - احتياطي"
														{...getInputProps('phone2')}
														fullWidth
														type="tel"
													/>
												</Stack>
											</Card>

											<Card sx={{p: 2}}>
												<Stack spacing={2}>
													<Typography variant="h6">التوصيل والاستلام</Typography>
													<Picker
														options={[
															{id: 'pickup', label: 'استلام شخصي'},
															{id: 'delivery', label: 'توصيل'},
														]}
														label="طريقة الاستلام"
														labelKey="label"
														valueKey="id"
														defaultValue="pickup"
														{...getInputProps('delivery_method')}
													/>

													{values.delivery_method === 'delivery' && (
														<>
															{accountIsDemo() && (
																<CustomAutoComplete
																	options={countries}
																	label="الدولة"
																	{...getInputProps('country')}
																	onChange={(e, opt) => setFieldValue('country', opt?.iso_code)}
																	labelKey="name"
																	valueKey="iso_code"
																/>
															)}

															<CustomAutoComplete
																options={values.country == 'LY' ? cities.map((c) => c.name) : []}
																label="المدينة"
																{...getInputProps('city')}
																onChange={(e, opt) => {
																	setFieldValue('city', opt ?? '');
																	setFieldValue('district', '');
																}}
																onInputChange={(event, newInputValue) => {
																	setFieldValue('city', newInputValue ?? '');
																}}
																freeSolo={values.country != 'LY'}
																value={values.city}
															/>

															{cityHasDistricts && (
																<Picker
																	options={selectedCity?.districts ?? []}
																	label="المنطقة"
																	labelKey="name"
																	valueKey="name"
																	{...getInputProps('district')}
																/>
															)}
															<TextField
																label="العنوان"
																placeholder="العنوان"
																{...getInputProps('address')}
																fullWidth
																type="text"
															/>
															{/* 		<TextField
														label="سعر التوصيل"
														placeholder="سعر التوصيل"
														{...getInputProps('delivery_price')}
														fullWidth
														type="number"
													/> */}
														</>
													)}
												</Stack>
												{isFeatureAllowed(order_selected_date_feature_flag) && (
													<TextField
														{...getInputProps('selected_date')}
														sx={{
															mt: 2,
														}}
														label={'موعد التسليم'}
														InputLabelProps={{
															shrink: true,
														}}
														fullWidth
														type="datetime-local"
													/>
												)}
											</Card>
											<Card sx={{p: 2}}>
												<Stack spacing={3}>
													<Typography variant="h6">معلومات اضافية</Typography>

													{/* 		<TextField
												label="االبريد الالكتروني"
												placeholder="البريد الالكتروني"
												{...getInputProps('email')}
												fullWidth
												type="email"
											/> */}

													<Picker
														options={orderStatusOptions.filter(
															(o) => o.key != 'cancelled' && o.key != 'returned',
														)}
														label="حالة الطلب"
														labelKey="label"
														valueKey="key"
														{...getInputProps('status')}
													/>

													<TextField
														label="ملاحظات"
														placeholder="ملاحظات اضافية عن الطلب"
														{...getInputProps('note')}
														fullWidth
														minRows={3}
														multiline
														type="text"
													/>

													<TextField
														fullWidth
														type="text"
														inputMode="numeric"
														pattern="\d*"
														InputProps={{min: 0}}
														label="عربون"
														{...getInputProps('down_payment')}
													/>

													{canAccess(addDiscountPermission) && (
														<TextField
															fullWidth
															type="text"
															inputMode="numeric"
															pattern="\d*"
															InputProps={{min: 0}}
															label="تخفيض"
															{...getInputProps('discount')}
														/>
													)}

													<BasicRadioGroup
														value={values.gateway}
														onChange={(e) => {
															setFieldValue('gateway', e.target.value);
														}}
														label="طريقة الدفع"
														options={[
															{label: 'كاش', value: 'cash'},
															{label: 'دفع الكتروني', value: 't_lync'},
														]}
													/>

													<Stack spacing={1} direction="row" justifyContent="space-between">
														{accountIsDemo() && (
															<Stack direction="row">
																<CheckboxWrapper {...getInputProps('is_cashier')} label="كاشير؟" />
																<InfoTip
																	devOnly
																	title="هذا الخيار يطلعلنا احني بس، العسال حيتم اضافة الطلب ككشير ويمكن تفعيلها لاي متجر اخر من ادمن فترينا"
																/>
															</Stack>
														)}

														<CheckboxWrapper {...getInputProps('payment_received')} label="تم الدفع؟" />
													</Stack>

													<SubmitButton isSubmitting={isSubmitting}>انشاء</SubmitButton>
												</Stack>
											</Card>
										</>
									)}
								</Stack>
							);
						}}
					</FormikWrapper>
				</Container>
			</AccessControlWrapper>
		</Page>
	);
}
