import {
	Box,
	Chip,
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
} from '@mui/material';

export default function Picker({
	size,
	options,
	name,
	label,
	helperText,
	error,
	valueKey,
	labelKey,
	onChange,
	value,
	defaultValue,
	fullWidth = false,
	disabled = false,
	displayEmpty = false,
	multiple = false,
	renderMenuItem,
	sx = {},
	renderValue,
	variant,
	loading = false,
}) {
	return (
		<FormControl size={size} sx={sx} fullWidth={fullWidth}>
			<InputLabel id="demo-simple-select-error-label">{label}</InputLabel>

			<Select
				renderValue={(selected) => {
					// console.log('🚀 ~ selected:', selected);
					if (multiple)
						return (
							<Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
								{selected.map((value, i) => {
									const option = options.find((o) => o == value || o[valueKey] == value);
									const _label = option?.[labelKey] ?? option;

									return <Chip key={i} label={_label} />;
								})}
							</Box>
						);
					else {
						const option = options.find((o) => o == selected || o[valueKey] == selected);
						const _label = option?.[labelKey] ?? option;

						return (
							<Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
								{_label}
								<Stack sx={{minWidth: 25, alignItems: 'flex-end'}}>
									<CircularProgress size={15} sx={{display: loading ? 'block' : 'none'}} />
								</Stack>
							</Stack>
						);
					}
				}}
				disabled={disabled}
				// displayEmpty={true}
				multiple={multiple}
				defaultValue={defaultValue}
				error={error}
				name={name}
				variant={variant}
				// labelId="demo-simple-select-error-label"
				// id="demo-simple-select-error"
				// native
				value={value}
				onChange={(e) => {
					if (typeof onChange === 'function') {
						onChange(
							e,
							options.find((o) => (o[valueKey] ?? o) == e.target.value),
						);
					}
				}}
				label={label}
				MenuProps={{
					PaperProps: {sx: {maxHeight: 300}},
				}}
				//   inputProps={{
				//     name: 'age',
				//     id: 'outlined-age-native-simple',
				//   }}
			>
				{/* <MenuItem aria-label="None" value="">
          {label}
        </MenuItem> */}
				{options.map((option) => (
					<MenuItem key={option[valueKey] ?? option} value={option[valueKey] ?? option}>
						{typeof renderMenuItem === 'function' ? renderMenuItem(option) : (option[labelKey] ?? option)}
					</MenuItem>
				))}
			</Select>
			{(error || helperText) && <FormHelperText error={error}>{helperText}</FormHelperText>}
		</FormControl>
	);
}
