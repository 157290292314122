import {Button} from '@mui/material';
import React from 'react';
import {useModal} from 'src/hooks/useModal';
import AddProductQtyModal from './AddProductQtyModal';

export default function AddQuantityButton({productId, variationId, refetch, disabled = false}) {
	const {open, close} = useModal();
	return (
		<div>
			<Button
				disabled={disabled}
				size={'small'}
				variant="outlined"
				sx={{minWidth: '1px'}}
				onClick={(e) => {
					open({
						title: 'اضافة كمية',
						children: (
							<AddProductQtyModal
								onSuccess={() => {
									// refetch();
									close();
									if (refetch) refetch();
								}}
								productId={productId}
								hasVariations={variationId != null}
								variationId={variationId}
							/>
						),
					});
				}}
			>
				إضافة
			</Button>
		</div>
	);
}
