import {useState} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import {NavLink as RouterLink, matchPath, useLocation, useNavigate} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {alpha, useTheme, experimentalStyled as styled} from '@mui/material';
import {Box, List, ListItem, Collapse, ListItemText, ListItemIcon} from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(
	({theme}) => ({
		...theme.typography.body2,
		height: 48,
		position: 'relative',
		textTransform: 'capitalize',
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(2.5),
		color: theme.palette.text.secondary,
		'&:before': {
			top: 0,
			right: 0,
			width: 3,
			bottom: 0,
			content: "''",
			display: 'none',
			position: 'absolute',
			borderTopLeftRadius: 4,
			borderBottomLeftRadius: 4,
			backgroundColor: theme.palette.primary.main,
		},
	}),
);

const ListItemIconStyle = styled(ListItemIcon)({
	width: 22,
	height: 22,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
	item: PropTypes.object,
	active: PropTypes.func,
};

function NavItem({item, active}) {
	const navigate = useNavigate();
	const theme = useTheme();
	const isActiveRoot = active(item.path);
	const {title, path, icon, info, children} = item;
	const [open, setOpen] = useState(isActiveRoot);
	const location = useLocation();

	const onArrowIconClicked = () => {
		setOpen((prev) => !prev);
	};
	const onNavItemClicked = () => {
		if(location.pathname === path){
			setOpen((prev) => !prev);
		}
		navigate(path);
	};

	const activeRootStyle = {
		color: 'primary.main',
		fontWeight: 'fontWeightMedium',
		bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
		'&:before': {display: 'block'},
	};

	const activeSubStyle = {
		color: 'primary.main',
		fontWeight: 'fontWeightMedium',
	};

	// if the nav item has children items
	if (children) {
		return (
			<>
				<ListItemStyle
					sx={{
						position: 'relative',
						...(isActiveRoot && activeRootStyle),
						'&:hover': {
							backgroundColor: 'rgba(0,0,0,0.04)'
						}
					}}
					onClick={onNavItemClicked}
				>
					<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
					<ListItemText disableTypography primary={title} />
					<Box onClick={(e)=>{
						e.stopPropagation();
						onArrowIconClicked();
					}} 
					sx={{
						position: 'absolute',
						right: 8,
						width: 40,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						cursor: 'pointer',
						'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' }, 
					  }}
					>

					<Icon
						icon={open ? arrowIosDownwardFill : arrowIosForwardFill}

					/>
					</Box>
				</ListItemStyle>
				{/* children nav items */}
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div">
						{children.map((item) => {
							const {title, path} = item;
							const isActiveSub = active(path);

							return (
								<ListItemStyle
									key={title}
									component={RouterLink}
									to={path}
									sx={{
										...(isActiveSub && activeSubStyle),
										paddingLeft: 7,
									}}
								>
									<ListItemIconStyle>
										<ListItemIconStyle>{item.icon && item.icon}</ListItemIconStyle>
									</ListItemIconStyle>
									<ListItemText sx={{fontSize: 12}} disableTypography primary={title} />
								</ListItemStyle>
							);
						})}
					</List>
				</Collapse>
			</>
		);
	}

	return (
		<ListItemStyle
			component={RouterLink}
			to={path}
			sx={{
				...(isActiveRoot && activeRootStyle),
			}}
		>
			<ListItemIconStyle>{icon && icon}</ListItemIconStyle>
			<ListItemText disableTypography primary={title} />
			{info && info}
		</ListItemStyle>
	);
}

NavSection.propTypes = {
	navConfig: PropTypes.array,
};

export default function NavSection({navConfig, ...other}) {
	const {pathname} = useLocation();
	const match = (path) => (path ? !!matchPath({path, end: true}, pathname) : false);

	return (
		<Box {...other}>
			<List disablePadding>
				{navConfig.map((item) => (
					<NavItem key={item.title} item={item} active={match} />
				))}
			</List>
		</Box>
	);
}
