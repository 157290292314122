import {Button, Card, Container, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import {useNavigate, useParams} from 'react-router-dom';
import SubmitButton from 'src/components/buttons/SubmitButton';
import {useDebounce} from 'src/hooks/useDebounce';
import {deletePurchases, getProducts, getPurchase} from 'src/utils/AdminApi';
import PurchaseItemInputs from './PurchaseItemInputs';
import usePurchaseForm from './usePurchaseForm';
import {toast} from 'react-toastify';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog';
import {handleRequestError} from 'src/utils/helperMethods';
import AccessControlWrapper from 'src/components/AccessControlWrapper';
import {canAccess} from 'src/utils/permissions';

export default function PurchasesUpdatePage() {
	const {id} = useParams();
	const {data: purchase = {items: [], description: '', date: ''}, refetch: refetchPurchase} =
		useQuery(
			['purchase', id],
			() => {
				return getPurchase(id);
			},
			{
				refetchOnWindowFocus: false,
				enabled: id != null,
			},
		);

	const {
		onSubmit,
		isSubmitting,
		setDescription,
		setdate,
		addNewItem,
		total,
		date,
		addItem,
		description,
		onRemoveItem,
		handleItemChange,
		productItemsInputs,
		// Expense
		otherExpensesInputs,
		onRemoveExpenseItem,
		addExpenseItem,
		handleExpenseItemChange,
		addNewExpenseItem,
	} = usePurchaseForm({
		purchaseId: purchase.id,
		defaultDescription: purchase.description,
		defaultDate: purchase.date,
		defaultSelectedProducts: purchase.items.filter((i) => i.product != null),
		defaultSelectedOtherExpenses: purchase.items.filter((i) => i.product == null),
		isUpdate: true,
		onSuccess: () => {
			toast.success('تم حفظ التغيرات');
			window.location.reload();
		},
	});
	const [filter, setFilter] = useState('');
	const debouncedFilter = useDebounce(filter, 250);

	const {data: products = [], refetch} = useQuery(
		['products', debouncedFilter],
		() => {
			return getProducts(
				`?_limit=20&publish_at_null=true&publish_at_null=false&name_contains=${debouncedFilter}`,
			);
		},
		{
			refetchOnWindowFocus: false,
		},
	);
	const nav = useNavigate();

	const handleDelete = () => {
		deletePurchases(id)
			.then(() => {
				toast.success('تم الحذف بنجاح');
				nav('/purchases');
			})
			.catch((err) => toast.error(handleRequestError(err)));
	};

	return (
		// currently this page should be accessed only by the owner
		<AccessControlWrapper>
			<Container>
				<Typography my={1} variant="h4">
					تعديل المشتريات
				</Typography>
				<Card sx={{p: 4}}>
					<form onSubmit={onSubmit}>
						<Stack spacing={2}>
							<Stack spacing={3} direction="row">
								<TextField
									fullWidth
									label="عنوان"
									placeholder="مشتريات شهر يناير"
									onChange={(e) => setDescription(e.target.value)}
									value={description}
									name="title"
									type="text"
									InputLabelProps={{shrink: true}}
								/>
								<TextField
									pattern="\d{4}-\d{2}-\d{2}"
									fullWidth
									InputLabelProps={{shrink: true}}
									label="تاريخ"
									placeholder="تاريخ"
									onChange={(e) => setdate(e.target.value)}
									value={date}
									name="date"
									type="date"
									required
								/>
							</Stack>
							<hr />

							<Stack justifyContent="space-between" direction="row">
								<Typography variant="subtitle2"> المنتجات</Typography>

								<Button onClick={addNewItem}>اضافة منتج اخر</Button>
							</Stack>
							<Stack spacing={3}>
								{productItemsInputs.map((item, index) => {
									return (
										<PurchaseItemInputs
											disabled={item.id != null}
											key={`Item-${index}`}
											showSelect
											onRemoveItem={onRemoveItem}
											onChange={handleItemChange}
											index={index}
											item={item}
											onDublicate={({id, ...item}) => addItem(item)}
											handleSearch={(term) => setFilter(term)}
											products={products}
											refetch={refetch}
										/>
									);
								})}
							</Stack>
							<hr />
							<Stack justifyContent="space-between" direction="row">
								<Typography variant="subtitle2">تكايف اخري</Typography>
								<Button onClick={addNewExpenseItem}>اضافة تكاليف اخري</Button>
							</Stack>
							<Stack spacing={3}>
								{otherExpensesInputs.map((item, index) => {
									return (
										<PurchaseItemInputs
											key={`Expense-${index}`}
											onRemoveItem={onRemoveExpenseItem}
											onChange={handleExpenseItemChange}
											index={index}
											item={item}
											onDublicate={({id, ...item}) => addExpenseItem(item)}
										/>
									);
								})}
							</Stack>

							<Typography>المجموع: {total}</Typography>
							<SubmitButton disabled={!canAccess()} isSubmitting={isSubmitting} size="medium">
								تعديل
							</SubmitButton>
						</Stack>
					</form>
				</Card>
				{canAccess() && (
					<Stack alignItems="center" justifyContent="center" direction="row" my={2}>
						<DeleteAlertDialog onDelete={handleDelete} />
						<Typography>حذف</Typography>
					</Stack>
				)}
			</Container>
		</AccessControlWrapper>
	);
}
