import {DateTime} from 'luxon';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import useDynamicInputsCRUD from 'src/hooks/useDynamicInputsCRUD';
import {createPurchases, updatePurchases} from 'src/utils/AdminApi';
import {getNumber} from 'src/utils/numberUtils';
import {getPurchaseTotalAmount} from './purchasesUtils';
import {canAccess} from 'src/utils/permissions';
import {toastAclError} from 'src/utils/toasts';
import useUser from 'src/hooks/useUser';
export default function usePurchaseForm({
	onSuccess,
	// important for update page to set inital state based on old data
	purchaseId = null,
	defaultSelectedProducts = [{total_quantity: 1, cost_per_unit: '', name: ''}],
	defaultSelectedOtherExpenses = [],
	defaultDescription = '',
	defaultDate = DateTime.now().toISODate(),
}) {
	// form inputs states
	const {user} = useUser()
	const [description, setDescription] = useState(defaultDescription ?? '');
	const [date, setdate] = useState(defaultDate ?? DateTime.now().toISODate());
	const [branch , setBranch] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		state: productItemsInputs,
		onRemoveItem,
		addItem,
		handleItemChange,
		setItems: setProductItemsInputs,
	} = useDynamicInputsCRUD(defaultSelectedProducts ?? []);

	const {
		state: otherExpensesInputs,
		onRemoveItem: onRemoveExpenseItem,
		addItem: addExpenseItem,
		handleItemChange: handleExpenseItemChange,
		setItems: setExpenses,
	} = useDynamicInputsCRUD(defaultSelectedOtherExpenses ?? []);

	const addNewItem = () => {
		addItem({total_quantity: 1, cost_per_unit: '', name: ''});
	};
	const addNewExpenseItem = () => {
		addExpenseItem({total_quantity: 1, cost_per_unit: '', name: ''});
	};

	const onSubmit = (e) => {
		e.preventDefault();
		if (total <= 0) return toast.error('يرجي اضافة تكاليف');
		if (!canAccess()) return toastAclError();
		// if(branch === ""){
		// 	return toast.error('يرجى اختيار فرع');
		// }
		setIsSubmitting(true);
		const requestBody = {
			description,
			date,
			// merge between expense items and product items, we split them just for frontend
			items: [
				// map items to send less data to server
				...productItemsInputs.map((item) => ({
					...item,
					product: item.product?.id,
					variation: item.variation?.id,
					branch: branch == '' ? null : branch,
				})),
				...otherExpensesInputs,
			],
		};

		let promise =
			purchaseId != null ? updatePurchases(purchaseId, requestBody) : createPurchases(requestBody);
		promise
			.then((res) => {
				onSuccess();
			})
			.finally(() => setIsSubmitting(false));
	};

	const total = getNumber(getPurchaseTotalAmount([...otherExpensesInputs, ...productItemsInputs]));

	useEffect(() => {
		// re-initialize input states after when we get new purchase id

		// i did this because the state was taking the default value from parent component before
		// fetch purchase record from api finish
		// try to comment this effect and see what happen
		setProductItemsInputs(defaultSelectedProducts);
		setExpenses(defaultSelectedOtherExpenses);
		setDescription(defaultDescription);
		setdate(defaultDate);
		setBranch(user.branch || "")
	}, [purchaseId]);
	return {
		total,
		onSubmit,
		setDescription,
		setdate,
		setBranch,
		addNewItem,
		addItem,
		date,
		branch,
		description,
		// productItemsInputs
		onRemoveItem,
		handleItemChange,
		productItemsInputs,
		// Expense
		otherExpensesInputs,
		onRemoveExpenseItem,
		addExpenseItem,
		handleExpenseItemChange,
		addNewExpenseItem,
		isSubmitting,
	};
}
