import React, {lazy, Suspense} from 'react';
import {useParams} from 'react-router';

export default function DevPages() {
	const {path} = useParams();

	const Comp = lazy(() => import(`src/pages/dev/${path}`));

	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<Comp />
			</Suspense>
		</div>
	);
}
