import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import React from 'react';

export default function PermissionsCheckboxList({
	allowed_actions = [],
	setFieldValue,
	allPermissions = [],
	fieldName,
}) 
{
	// to make an object that has resource as key and permissions as array of values
	const groupedPermissions = allPermissions.reduce((acc,permission) => {
		if (!acc[permission.resource]) acc[permission.resource] = [];
        acc[permission.resource].push(permission);
        return acc;
	},{})
	// to translate permission labels to arabic
	const resourceLabels = {
		orders: "صلاحيات الطلب",
		products: "صلاحيات المنتج",
	};
	return (
		<Stack spacing={2}>
		{Object.entries(groupedPermissions).map(([resource, permissions]) => (
		  <Stack key={resource} spacing={1}>
			<Typography variant="h6">{resourceLabels[resource] || 'صلاحيات اخرى'}</Typography>
			<Stack direction="row" flexWrap="wrap">
			  {permissions.map((thisPermission) => {
				const isChecked = allowed_actions.some(
				// Checks if the current permission (id & resource) exists in allowed_actions
				// If found, it means the checkbox should be checked
				(p) => p.id === thisPermission.id && p.resource === resource
				);
	
				return (
				  <FormControlLabel
					key={thisPermission.id}
					control={
					  <Checkbox
						onChange={(e) => {
						  if (e.target.checked) {
							setFieldValue(fieldName, [
							  ...allowed_actions,
							  { id: thisPermission.id, resource },
							]);
						  } else {
							setFieldValue(
							  fieldName,
							  allowed_actions.filter(
								(p) => !(p.id === thisPermission.id && p.resource === resource)
							  )
							);
						  }
						}}
						name={fieldName}
						value={thisPermission.id}
						checked={isChecked}
					  />
					}
					label={thisPermission.label}
				  />
				);
			  })}
			</Stack>
		  </Stack>
		))}
	  </Stack>
	);
}
