import React, {useState} from 'react';
import {useQuery} from 'react-query';
import Picker from 'src/components/inputs/Picker';
import useSite from 'src/hooks/useSite';
import useUser from 'src/hooks/useUser';
import {request, updateAdminUserInfo} from 'src/utils/AdminApi';

export function useBranches() {
	const site = useSite();
	return useQuery(['branches', site.id], () => request('branches'), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
}
export default function BranchPicker({
	value,
	onChange,
	variant = 'filled',
	filterOptions,
	allowNone = true,
	label,
	minWidth = 150,
	fullWidth,
	disabled,
	options: _options,
}) {
	const {user} = useUser();
	const {data, isLoading} = useBranches();

	const branches = data ?? [];
	const options = _options ?? (allowNone ? [{name: '-', id: 'none'}, ...branches] : branches);
	const [isSubmitting, setIsSubmitting] = useState(false);

	if (isLoading || branches?.length <= 1) return;
	return (
		<Picker
			disabled={disabled}
			fullWidth={fullWidth}
			defaultValue={user.branch ?? ''}
			value={value}
			sx={{minWidth}}
			variant={variant}
			options={typeof filterOptions === 'function' ? filterOptions(options) : options}
			label={label ?? 'الفرع'}
			valueKey={'id'}
			labelKey={'name'}
			loading={isSubmitting}
			renderMenuItem={(o) => (o.id ? `${o.name}` : o)}
			onChange={async (e, option) => {
				if (typeof onChange === 'function') {
					try {
						setIsSubmitting(true);
						const res = await onChange(e, option);
						return res;
					} finally {
						setIsSubmitting(false);
					}
				}
				await updateAdminUserInfo({
					...user,
					branch: option?.id === 'none' ? null : (option?.id ?? null),
				});
				window.location.reload();
			}}
		/>
	);
}
