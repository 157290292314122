import {Box, Button, Card, Checkbox, Stack, Typography} from '@mui/material';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import NameAvatar from 'src/components/ui/NameAvatar';
import {findOneOrderPermission} from 'src/permissionsList';
import {getDateTime} from 'src/utils/date';
import {getPriceNumber} from 'src/utils/numberUtils';
import {orderIsCanceledOrReturned, orderStatus} from 'src/utils/orderUtils';
import {canAccess, isOrderAssignee} from 'src/utils/permissions';
import {getSiteCurrency} from 'src/utils/selectors';

// Order item

export function OrderItem({order, isChecked, handleCheck}) {
	const currency = getSiteCurrency();
	const [date, time] = getDateTime(order.created_at);
	const isPickup = order?.delivery_info?.type === 'pickup';
	const isCanceledOrReturned = orderIsCanceledOrReturned(order);

	const paymentStatusEmoji = isCanceledOrReturned ? '' : order.payment_received ? '✅' : '⌛';
	// const shouldShowDownPayment = order.down_payment > 0 && order.payment_received !== true;
	const shouldShowDownPayment = order.down_payment > 0;
	return (
		<div>
			<Card variant="outlined" sx={{p: 2, paddingBottom: 3}}>
				<Stack spacing={0.5}>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Stack spacing={1} direction="row" alignItems="center">
							<Typography pt={1}>{'#' + order.tracking_number}</Typography>
						</Stack>
						{/* only for owner */}
						{/* {canAccess() && (
							<Checkbox size="small" type={'checkbox'} onChange={handleCheck} checked={isChecked} />
						)} */}

						{/* <NameAvatar name={'حاتم'} /> */}
					</Stack>
					<Typography variant="subtitle1" color="gray">
						{order.customer?.name ? `اسم الزبون: ${order.customer.name}` : 'كاشير'}
					</Typography>
					<Typography
						color="gray"
						sx={{
							lineHeight: 1,
							maxHeight: 32,
							overflow: 'hidden',
							marginTop: 2,
						}}
					>
						{isPickup ? `استلام شخصي` : `توصيل الي: ${order.delivery_info?.city ?? order.address}`}
					</Typography>
					<Typography color="gray">
						تاريخ انشاء الطلب : {new Date(order.created_at).toLocaleString('ar-AE')}
					</Typography>
					{!order.is_cashier && order.status === 'delivered' ? (
						<Typography color="gray">
							تم التوصيل بتاريخ {new Date(order.fulfillment_date).toLocaleString('ar-AE')}
						</Typography>
					) : (
						<Typography color={isCanceledOrReturned ? 'red' : 'gray'}>
							حالة الطلب: {orderStatus[order.status]?.label}
						</Typography>
					)}
					<Box sx={{position: 'relative'}}>
						<Stack my={1} spacing={1} direction="row" justifyContent="space-between">
							<Typography fontWeight="bold">
								المجموع: {getPriceNumber(order.total) + currency} {paymentStatusEmoji}
							</Typography>
							{shouldShowDownPayment && (
								<Typography>العربون: {getPriceNumber(order.down_payment) + currency}</Typography>
							)}
						</Stack>
						{(canAccess(findOneOrderPermission) || isOrderAssignee(order)) && (
							<Link to={'/orders/' + order.uuid} color="inherit" underline="hover">
								<Button
									sx={{
										color: 'black',
										p: 1,
										borderRadius: '2px',

										width: '100%',
									}}
									variant="contained"
									color="secondary"
								>
									عرض الطلب
								</Button>
							</Link>
						)}
					</Box>
				</Stack>
			</Card>
		</div>
	);
}
