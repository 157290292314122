import React, {useEffect, useRef} from 'react';
import {confirmable, createConfirmation} from 'react-confirm';
import BasicModal from './BasicModal';
import {Button, Stack} from '@mui/material';
import ThemeConfig from 'src/theme';

const YesOrNoModal = (props) => {
	const {
		okLabel = 'OK',
		cancelLabel = 'Cancel',
		options: {dismissable = true},
		title = 'Confirmation',
		confirmation,
		show,
		proceed,
		dismiss,
		cancel,
	} = props;
	return (
		<ThemeConfig>
			<BasicModal dismissable={dismissable} title={title} 
			onClose={() => {
				dismiss();
				proceed(false);
			}}			
			isVisible={show}>
				<Stack spacing={3} justifyContent="space-between">
					<Button variant="contained" onClick={() => proceed(true)}>
						نعم
					</Button>

					<Button variant="outlined" onClick={() => proceed(false)}>
						لا
					</Button>
				</Stack>
			</BasicModal>
		</ThemeConfig>
	);
};
const ChoicesModal = (props) => {
	const {title = 'Confirmation', show, proceed, dismiss, cancel, choices = []} = props;

	const handleKeyPress = (event) => {
		alert('event');
		if (event.key === 'Enter') {
			// Trigger the second button click
			dismiss();
		}
	};

	return (
		<ThemeConfig>
			<BasicModal title={title} onClose={() => dismiss()} isVisible={show}>
				<Stack spacing={3} justifyContent="space-between">
					{choices.map((choice) => {
						return (
							<Button
								variant={choice.variant}
								onClick={() => {
									dismiss();
									if (typeof choice.action === 'function') choice.action();
								}}
								key={choice.id}
							>
								{choice.text}
							</Button>
						);
					})}
				</Stack>
			</BasicModal>
		</ThemeConfig>
	);
};

export function askYesOrNo(title, options = {}) {
	return createConfirmation(confirmable(YesOrNoModal))({
		title,
		options,
	});
}
export function askForOption(title, choices) {
	return createConfirmation(confirmable(ChoicesModal))({
		title,
		choices,
	});
}
