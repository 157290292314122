import {calcPriceAfterDiscount} from 'src/utils/discountUtils';
import {getNumber, sumBy} from './numberUtils';
import {isNullOrEmpty} from './helperMethods';

export const getItemTotalPrice = (price, discount, quantity) => {
	return calcPriceAfterDiscount(price, discount) * quantity;
};

export const isCartItemValid = (cartItems) => {
	for (let cartItem of cartItems) {
		if (!isNullOrEmpty(cartItem.inputs)) {
			for (let productInput of cartItem.inputs) {
				if (productInput.required !== true) continue;
				const productUserInput = cartItem.selectedInputs?.find((i) => i.name === productInput.name);
				if (isNullOrEmpty(productUserInput?.value)) return false;
			}
		}
	}

	return true;
};
// used by cashier and productgridselection to return order item object based on product or variation
export const buildOrderItem = (item) => {
	const {price, discount, selectedQuantity, selectedInputs} = item;
	return {
		price,
		discount,
		product: item.product,
		variation: item.variation,
		quantity: getNumber(selectedQuantity, 1),
		inputs: selectedInputs,
		branch: item.branch,
	};
};
// related to src/pages/products/common/ProductsGridSelection/ProductsGridSelectionItem.jsx addItem method
export const buildCartItemFromOrderItem = (item) => {
	let isVariation = item.variation != null;

	return {
		// copy product meta attributes like name
		...item.product,
		...item,
		quantity: isVariation
			? item.variation.derived.branch_quantity
			: item.product.derived.branch_quantity,
		selectedQuantity: item.quantity,
		product: item.product.id,
		variation: item.variation?.id,
		id: isVariation
			? `variation-${item.variation?.id ?? item.variation}`
			: (item.product?.id ?? item.product),
		isVariation,
	};
};
