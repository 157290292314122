import {useState} from 'react';

type SelectedItem = {id: number; selectedQuantity: number; quantity: number; infinite: boolean};

/* 
	this hook use on selecting order items on cashier page and exchange page
	the component that will use this hook is responsible on how to structure the array
	but this hook will handle the add/update/delete based on id and selected quantity
*/
export default function useOrderItemsSelection(initialState = []) {
	const [addedProducts, setAddedProducts] = useState<Array<SelectedItem>>(initialState);
	/**
	 *
	 * @param {object} item maybe product or variation object
	 */
	function handleAddItem(item: SelectedItem) {
		setAddedProducts((prev) => [...prev, {...item, selectedQuantity: 1}]);
	}
	function handleRemoveItem(id: number) {
		setAddedProducts((prevState) => {
			let found = false;
			return prevState.filter((item) => {
				if (item.id == id && !found) {
					found = true;
					return false;
				}
				return true;
			});
		});
	}
	function checkAddedItemExists(id: number) {
		return addedProducts.some((item) => item.id == id);
	}
	function handleItemQuantityChange(id: number, newQuantity: number) {
		// if (newQuantity < 1) return;
		const newAddedProducts = addedProducts.map((item) => {
			if (item.id == id) {
				// if (!item.infinite && newQuantity > item.quantity) return item;
				item.selectedQuantity = newQuantity;
			}
			return item;
		});
		setAddedProducts(newAddedProducts);
	}
	function handleItemChange(id: number, updatedItem: SelectedItem) {
		const newAddedProducts = addedProducts.map((item) => {
			if (item.id == id) {
				return updatedItem;
			}
			return item;
		});
		setAddedProducts(newAddedProducts);
	}
	function updateItem(id: number, updates) {
		setAddedProducts((prev) => {
			return prev.map((item) => {
				if (item.id == id) {
					return {...item, ...updates};
				}
				return item;
			});
		});
	}

	return {
		setAddedProducts,
		handleItemQuantityChange,
		checkAddedItemExists,
		handleAddItem,
		handleRemoveItem,
		addedProducts,
		updateItem,
		handleItemChange,
		resetSelections: () => setAddedProducts([]),
	};
}
