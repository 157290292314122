import {Button, Card, Stack, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useQuery} from 'react-query';
import SubmitButton from 'src/components/buttons/SubmitButton';
import {useDebounce} from 'src/hooks/useDebounce';
import {getProducts} from 'src/utils/AdminApi';
import PurchaseItemInputs from './PurchaseItemInputs';
import usePurchaseForm from './usePurchaseForm';

export default function PurchaseCreateForm({onCreate}) {
	const {
		onSubmit,
		setDescription,
		setdate,
		addNewItem,
		total,
		date,
		addItem,
		description,
		onRemoveItem,
		handleItemChange,
		productItemsInputs,
		// Expense
		otherExpensesInputs,
		onRemoveExpenseItem,
		addExpenseItem,
		handleExpenseItemChange,
		addNewExpenseItem,
		branch,
		setBranch
	} = usePurchaseForm({onSuccess: onCreate});
	const [filter, setFilter] = useState('');
	const debouncedFilter = useDebounce(filter, 250);

	const {data: products = [], refetch} = useQuery(
		['products', debouncedFilter],
		() => {
			return getProducts(
				`?_limit=20&publish_at_null=true&publish_at_null=false&name_contains=${debouncedFilter}`,
			);
		},
		{
			refetchOnWindowFocus: false,
			// refetchOnMount: false,
		},
	);

	return (
		<Card sx={{p: 4}}>
			<form onSubmit={onSubmit}>
				<Stack spacing={2}>
					<Stack spacing={3} direction="row">
						<TextField
							fullWidth
							label="عنوان"
							placeholder="مشتريات شهر يناير"
							onChange={(e) => setDescription(e.target.value)}
							value={description}
							name="title"
							type="text"
							InputLabelProps={{shrink: true}}
						/>
						<TextField
							pattern="\d{4}-\d{2}-\d{2}"
							fullWidth
							InputLabelProps={{shrink: true}}
							label="تاريخ"
							placeholder="تاريخ"
							onChange={(e) => setdate(e.target.value)}
							value={date}
							name="date"
							type="date"
							required
						/>
					</Stack>
					<hr />

					<Stack justifyContent="space-between" direction="row">
						<Typography variant="subtitle2"> المنتجات</Typography>

						<Button onClick={addNewItem}>اضافة منتج اخر</Button>
					</Stack>
					<Stack spacing={3}>
						{productItemsInputs.map((item, index) => {
							return (
								<PurchaseItemInputs
									key={`Item-${index}`}
									showSelect
									onRemoveItem={onRemoveItem}
									onChange={handleItemChange}
									index={index}
									item={item}
									onDublicate={addItem}
									handleSearch={(term) => setFilter(term)}
									products={products}
									refetch={refetch}
									branch={branch}
									setBranch={setBranch}
								/>
							);
						})}
					</Stack>
					<hr />
					<Stack justifyContent="space-between" direction="row">
						<Typography variant="subtitle2">تكايف اخري</Typography>
						<Button onClick={addNewExpenseItem}>اضافة تكاليف اخري</Button>
					</Stack>
					<Stack spacing={3}>
						{otherExpensesInputs.map((item, index) => {
							return (
								<PurchaseItemInputs
									key={`Expense-${index}`}
									onRemoveItem={onRemoveExpenseItem}
									onChange={handleExpenseItemChange}
									index={index}
									item={item}
									onDublicate={addExpenseItem}
								/>
							);
						})}
					</Stack>

					<Typography>المجموع: {total}</Typography>
					<SubmitButton size="medium">اضافة</SubmitButton>
				</Stack>
			</form>
		</Card>
	);
}
