import {Stack} from '@mui/material';
import {useQuery} from 'react-query';
import TableWrapper from 'src/components/wrappers/TableWrapper';
import {LoadingView} from 'src/routes';
import {request} from 'src/utils/AdminApi';
export default function DynamicList({
	api_route,
	transformData = (d) => d,
	RowComponent,
	cols,
	parent,
}) {
	const route = `${api_route}`;
	let {data, isLoading, refetch, error} = useQuery(route, () => request(route));

	const results = data ? transformData(data) : [];
	const renderList = () => {
		if (!RowComponent) {
			if (cols) {
				return <TableWrapper headerColumns={cols} data={results} />;
			} else {
				return null;
			}
		} else {
			if (parent) {
				return (
					<parent.Component {...parent.props}>
						{results.map((item, index) => (
							<RowComponent key={item.id ?? index} item={item} />
						))}
					</parent.Component>
				);
			} else {
				return (
					<Stack spacing={2}>
						{results.map((item, index) => (
							<RowComponent key={item.id ?? index} item={item} />
						))}
					</Stack>
				);
			}
		}
	};
	return (
		<div>
			{error && (
				<div>
					{error.message ?? 'حدث خطا غير متوقع'}
					<button
						onClick={() => {
							refetch();
						}}
					>
						اعادة المحاولة
					</button>
				</div>
			)}

			{isLoading && (
				<div>
					<LoadingView />
				</div>
			)}

			{!isLoading && results?.length === 0 && <div>لا يوجد نتائج</div>}

			{results?.length > 0 && renderList()}
		</div>
	);
}
