import {DynamicForm} from 'vetrina-ui';
import {request} from 'src/utils/AdminApi';
import {toast} from 'react-toastify';
import {nameValidation} from 'src/schema/commanValidationSchema';
import FileUploadField from 'src/components/inputs/ImageCropPicker.js/FileUploadField';
import useCategories from 'src/hooks/useCategories';

export default function CategoryForm({initialData, onSuccess}) {
	const isEdit = initialData?.id;
	const {categories} = useCategories();

	return (
		<DynamicForm
			submitButtonText={isEdit ? 'تعديل' : 'اضافة'}
			initialValues={initialData}
			config={{
				inputs: [
					{
						name: 'name',
						type: 'text',
						label: 'الاسم',
						validation: nameValidation.required('يرجي ادخال الاسم'),
					},
					{
						name: 'parent',
						type: 'picker',
						label: 'التصنيف الرئيسي',
						options: [{id: null, name: 'لا يوجد'}, ...categories],
						labelKey: 'name',
						valueKey: 'id',
					},
					{
						name: 'image',
						component: ({formik, input}) => {
							const inputName = input.name;
							return (
								<FileUploadField
									value={formik.values[inputName]}
									defaultImage={initialData?.[inputName]?.url}
									label={'الصورة'}
									onChange={(newValue) => {
										formik.setFieldValue(input.name, newValue);
									}}
								/>
							);
						},
					},
				],
			}}
			onSubmit={async (formData) => {
				try {
					let res;
					if (isEdit) res = await request(`/categories/${initialData.id}`, 'PUT', formData);
					else
						res = await request('/categories', 'POST', {
							...formData,
							row_order: Math.max(...categories.map((c) => c.row_order)) + 1,
						});

					toast.success(isEdit ? 'تم التعديل بنجاح' : 'تم الاضافة بنجاح');
					if (onSuccess) onSuccess(res);
				} catch (err) {
					toast.error('حدث خطاء غير متوقع');
				}
			}}
		/>
	);
}
