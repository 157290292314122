import {
	Button,
	Card,
	Container,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {Container as DContainer, Draggable} from 'react-smooth-dnd';
import {toast} from 'react-toastify';
import PlusButton from 'src/components/buttons/PlusButton';
import DeleteAlertDialog from 'src/components/modals/DeleteAlertDialog.js';
import NavButton from 'src/components/NavButton';
import StyledLink from 'src/components/ui/StyledLink';
import useCategories from 'src/hooks/useCategories';
import {useDndScrollWorkaround} from 'src/hooks/useDndScrollWorkaround';
import {useModal} from 'src/hooks/useModal';
import CategoryForm from 'src/pages/categories/CategoryForm';
import {
	deleteCategory as deleteCategoryRequest,
	reorderCategory,
	request,
} from 'src/utils/AdminApi';
import {arrayMoveImmutable} from 'src/utils/dndUtils';
import {getImageFormat} from 'src/utils/imageUtils';
import {handleRequestError} from 'src/utils/helperMethods';
import {cloneDeep} from 'lodash';

export default function Categories() {
	const {open, close} = useModal();
	const {categories, updateCategory, deleteCategory, setCategories, addCategory} = useCategories();
	useDndScrollWorkaround();

	const onDrop = async ({removedIndex, addedIndex, ...other}) => {
		const oldArray = cloneDeep(categories);
		const newArray = arrayMoveImmutable(categories, removedIndex, addedIndex);
		setCategories(newArray);

		try {
			await request('categories/reorder', 'post', {categories: newArray.map((a) => a.id)});
		} catch (err) {
			toast.error(handleRequestError(err));
			setCategories(oldArray);
		}
	};

	return (
		<Page title="التصنيفات">
			<Container maxWidth="md">
				<Card sx={{p: 2}}>
					<Stack sx={{mb: 1, mx: 2}} justifyContent="space-between" alignItems="center" direction="row">
						<Typography mt={1} variant="h4">
							التصنيفات
						</Typography>

						<PlusButton
							onClick={() => {
								open({
									title: 'اضافة تصنيف',
									children: (
										<CategoryForm
											onSuccess={(newCategory) => {
												addCategory(newCategory);
												close();
											}}
										/>
									),
								});
							}}
						/>
					</Stack>
					<List>
						<DContainer
							lockAxis="y"
							// onDragStart={() => {}}
							dragHandleSelector=".drag-handle"
							onDrop={onDrop}
						>
							{categories.map((cat) => (
								<Draggable key={cat.id}>
									<ListItem>
										<ListItemButton className="drag-handle">
											<ListItemIcon>
												<img
													alt={cat.name}
													loading="lazy"
													src={getImageFormat(cat.image)}
													width={30}
													height={30}
													style={{marginLeft: 4}}
												/>
											</ListItemIcon>
											<ListItemText
												primary={
													<StyledLink to={`/categories-stats?categoryId=${cat.id}`}>{cat.name}</StyledLink>
												}
											/>

											<ListItemIcon>
												<NavButton variant="text" text="المنتجات" to={`/products?categoryId=${cat.id}`} />
											</ListItemIcon>
											<ListItemIcon>
												<Button
													onClick={() => {
														open({
															title: 'تعديل التصنيف',
															children: (
																<CategoryForm
																	initialData={{...cat, parent: cat.parent?.id}}
																	onSuccess={(newCategory) => {
																		updateCategory(newCategory);
																		close();
																	}}
																/>
															),
														});
													}}
													variant="text"
												>
													تعديل
												</Button>
											</ListItemIcon>
											<ListItemIcon>
												<DeleteAlertDialog
													onDelete={() => {
														deleteCategoryRequest(cat.id);
														deleteCategory(cat.id);
													}}
												/>
											</ListItemIcon>
											<ListItemIcon>
												<DragHandleIcon />
											</ListItemIcon>
										</ListItemButton>
									</ListItem>
									<Divider />
								</Draggable>
							))}
						</DContainer>
					</List>
				</Card>
			</Container>
		</Page>
	);
}
