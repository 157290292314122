import {LoadingButton} from '@mui/lab';
import {Button, Stack, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import BranchPicker from 'src/components/BranchPicker';
import {showModel} from 'src/components/modals/showModel';
import useUser from 'src/hooks/useUser';
import {manualUpdateProductQuantity, updateProduct} from 'src/utils/AdminApi';
import {handleRequestError, isNullOrEmpty} from 'src/utils/helperMethods';
import {isNumber} from 'src/utils/numberUtils';

export default function AddProductQtyModal({
	// function to close the modal
	dismiss,
	onSuccess,
	productId,
	variationId,
}) {
	const [qty, setQty] = useState();
	const [cost_per_unit, setCost_per_unit] = useState('');
	const [branch, setBranch] = useState(null);
	const [isSubmitting, setisSubmitting] = useState(false);
	const {user} = useUser();
	const userBranch = user.branch;

	const hasVariations = variationId != null;
	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();
				const body = !hasVariations
					? {
							addedQuantity: qty,
							cost_per_unit,
							branch,
						}
					: {
							variations: [
								{
									id: variationId,
									cost_per_unit: cost_per_unit,
									addedQuantity: qty,
									branch: branch,
								},
							],
						};

				setisSubmitting(true);
				try {
					await updateProduct(productId, body);
					onSuccess();
				} catch (err) {
					toast.error(handleRequestError(err));
				}
				setisSubmitting(false);
			}}
		>
			<Stack spacing={6}>
				<Stack spacing={1}>
					{!userBranch && (
						<BranchPicker
							fullWidth
							label={'الفرع'}
							allowNone={false}
							value={branch}
							onChange={(e) => setBranch(e.target.value)}
						/>
					)}
					<Typography variant="body2">ستضاف الكمية المطلوبة الى المشتريات.</Typography>
					<TextField
						label="الكمية"
						autoComplete="off"
						sx={{flex: '1 1 auto'}}
						type="number"
						value={qty ?? ''}
						onChange={(e) => +e.target.value >= 0 && setQty(e.target.value)}
					/>
					<TextField
						name="cost_per_unit"
						label="تكلفة القطعة"
						autoComplete="off"
						sx={{flex: '1 1 auto'}}
						type="number"
						value={cost_per_unit ?? ''}
						onChange={(e) => +e.target.value >= 0 && setCost_per_unit(e.target.value)}
					/>
				</Stack>
				<LoadingButton
					loading={isSubmitting}
					type="submit"
					disabled={!isNumber(qty)}
					variant="contained"
				>
					تحديث
				</LoadingButton>
			</Stack>
		</form>
	);
}
