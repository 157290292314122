import React from 'react';
import {useQuery} from 'react-query';
import Picker from 'src/components/inputs/Picker';
import useUser from 'src/hooks/useUser';
import {request, updateAdminUserInfo} from 'src/utils/AdminApi';
import equalId from 'src/utils/equalId';

export default function ItemBranchPicker({variationId, stocks = [], value, onChange}) {
	const {user} = useUser();
	const {data, isLoading} = useQuery('branches', () => request('branches'), {
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});

	if (isLoading || data?.length <= 1 || user.branch) return;

	const branches = data ?? [];
	const options = [
		...branches.filter((b) => {
			const s = stocks.find((s) => s.branch == b.id && equalId(s.variation, variationId));
			return !!s && s.quantity > 0;
		}),
	];
	return (
		<Picker
			value={value}
			sx={{minWidth: 150}}
			variant={'filled'}
			options={options}
			label={'الفرع'}
			valueKey={'id'}
			labelKey={'name'}
			renderMenuItem={(o) => (o.id ? `${o.name}` : o)}
			onChange={async (e, option) => {
				if (typeof onChange === 'function') return onChange(e, option);
			}}
		/>
	);
}
